










import Vue from "vue";
import coreApiClient from "@/services/apis/coreApiClient";
import DataContainer from "@vuetifyx/common/DataContainer";
import LadiPageConfigureHelp from "./mkt-links/LadiPageConfigureHelp.vue";
import userManager from "@/services/userManager";

export default Vue.extend({
  data() {
    return {
      self: this,
      market: null,
      tableOptions: null,
    };
  },
  methods: {
    makeTableOptions(market) {
      return {
        attrs: {
          "item-key": "_id",
          "sort-by": "createdTime",
          "sort-desc": true,
          "show-select": true,
        },
        content: {
          name: "Quản lý MKT Link",
          urlEnabled: true,
          search: {},
          displayFields: {
            name: {
              text: "Tên",
              sortable: true,
            },
            description: {
              text: "Mô tả",
            },
            shopeeName: {
              text: "Shopee Name",
              sortable: true,
              hidden: true,
            },
            baseUrl: {
              text: "Base URL",
              sortable: true,
              options: {
                href(value) {
                  return value;
                },
                attrs: {
                  target: "_blank",
                },
              },
            },
            mktPlatform: {
              text: "Nền tảng MKT",
              options: {
                subProp: "mktPlatform.name",
                label: true,
                sortBy: "mktPlatformId",
              },
            },
            productGroup: {
              text: "Nhóm sản phẩm",
              options: {
                subProp: "productGroup.sku",
                label: true,
                sortBy: "productGroupId",
              },
            },
            giftedProductGroup: {
              text: "Nhóm sản phẩm quà tặng",
              options: {
                subProp: "giftedProductGroup.sku",
                label: true,
                sortBy: "giftedProductGroupId",
              },
            },
            productCombos: {
              text: "Combo sản phẩm",
              options: {
                labels: true,
                labelItems(value) {
                  return value.name;
                },
              },
            },
            mktUser: {
              text: "User MKT",
              options: {
                subProp: "mktUser.name",
                sortBy: "mktUserId",
                attrs(_, item) {
                  const attrs: any = {};
                  if (item.mktUser && item.mktUser.ht === "nv") {
                    attrs.class = "red--text";
                  }
                  return attrs;
                },
              },
            },
            contentUser: {
              text: "User Content",
              options: {
                subProp: "contentUser.name",
                sortBy: "contentUserId",
                attrs(_, item) {
                  const attrs: any = {};
                  if (item.contentUser && item.contentUser.ht === "nv") {
                    attrs.class = "red--text";
                  }
                  return attrs;
                },
              },
            },
            designUser: {
              text: "User Design",
              options: {
                subProp: "designUser.name",
                sortBy: "designUserId",
                attrs(_, item) {
                  const attrs: any = {};
                  if (item.designUser && item.designUser.ht === "nv") {
                    attrs.class = "red--text";
                  }
                  return attrs;
                },
              },
            },
            createdTime: {
              text: "Ngày tạo",
              sortable: true,
              options: {
                filter: "dateTime",
              },
            },
            action: {},
          },
          topActionButtons: {
            export: userManager.checkRole(["kt", "mkt", "tp", "rd", "rdl"]) && {
              attrs: {
                color: "default",
                dark: false,
              },
              content: {
                text: "Xuất Excel",
                icon: "mdi-microsoft-excel",
              },
              on: {
                xClick({ self }) {
                  const dataTable = self.context();
                  let findAllOptions = dataTable.getFindAllOptions();
                  if (dataTable.model.value.length) {
                    findAllOptions = {
                      payload: JSON.stringify({
                        filters: [
                          {
                            key: "_id",
                            operator: "in",
                            value: dataTable.model.value.map(
                              (item) => item._id
                            ),
                          },
                        ],
                        limit: -1,
                      }),
                    };
                  }
                  coreApiClient.download(
                    "mktLinks",
                    `@/export`,
                    findAllOptions
                  );
                },
              },
            },
          },
          template: {
            itemActionButtons: {
              edit: userManager.checkRole(["kt", "mkt", "tp", "rd", "rdl"]) && {
                target: {
                  dialog: {
                    ext: {
                      subTitleMaker: "name",
                    },
                  },
                },
              },
              delete: userManager.checkRole(["mkt", "tp", "rd", "rdl"]) && {},
              ladiPageConfigureHelp: {
                content: {
                  icon: "mdi-view-list-outline",
                },
                target: {
                  tooltip: { content: { text: "Hướng dẫn cấu hình LadiPage" } },
                  dialog: {
                    attrs: {
                      width: "900px",
                    },
                    content: {
                      autofocus: false,
                      toolbar: {
                        title: "Hướng dẫn cấu hình LadiPage",
                        subTitle(self) {
                          const btn = self.context();
                          const { item } = btn.context();
                          return item.name;
                        },
                      },
                      content: {
                        type: "XForm",
                        attrs: {},
                        makeAttrs(attrs: any = {}, dialog) {
                          const { item } = dialog.context().context();
                          attrs.xOptions = {
                            content: {
                              sections: {
                                default: {
                                  header: "Thông tin chung",
                                  fields: {
                                    mktLink: {
                                      attrs: {
                                        label: "MKT Link",
                                        "prepend-icon": "mdi-link",
                                      },
                                      ext: {
                                        defaultValue: `${item.baseUrl}`,
                                      },
                                    },
                                    apiLink: {
                                      attrs: {
                                        label: "API Link",
                                        "prepend-icon": "mdi-link",
                                      },
                                      ext: {
                                        defaultValue: item.mainProductGroup?.sku
                                          ? `${
                                              location.origin
                                            }/core-api/orders/@/ladiPage/${
                                              item._id
                                            }/${encodeURIComponent(
                                              item.mktUser.email
                                            )}/${encodeURIComponent(
                                              item.mainProductGroup.sku
                                            )}?secret=${item.secret}`
                                          : "Không tìm thấy nhóm sản phẩm chính",
                                      },
                                    },
                                  },
                                },
                                form: {
                                  header: "Cấu hình form",
                                  fields: {
                                    main: {
                                      type: LadiPageConfigureHelp,
                                      attrs: {
                                        item,
                                      },
                                    },
                                  },
                                },
                              },
                            },
                          };
                          attrs.xContext = dialog;
                          attrs.xData = new DataContainer({});
                          return attrs;
                        },
                      },
                      buttons: {
                        cancel: {
                          content: {
                            text: "Đóng",
                          },
                        },
                      },
                    },
                  },
                },
              },
            },
          },
          findAllOptionsFilter(options) {
            options.filters.push({
              key: "marketId",
              operator: "equal_to",
              value: this.context().$route.params.marketId,
            });
            return options;
          },
        },
        ext: {
          dataProvider: {
            async findAll(options) {
              const { items, count } = await coreApiClient.call(
                "mktLinks",
                "findAll",
                options
              );
              return [items, count];
            },
            async insert(item, dataTable) {
              item.marketId = dataTable.context().$route.params.marketId;
              return await coreApiClient.call(
                "mktLinks",
                "create",
                undefined,
                item
              );
            },
            async update(item) {
              return await coreApiClient.call(
                "mktLinks",
                "update",
                {
                  id: item._id,
                },
                {
                  data: item,
                }
              );
            },
            async delete(item) {
              return await coreApiClient.call("mktLinks", "delete", {
                id: item._id,
              });
            },
          },
          default: {
            defaultInputs: {
              name: {
                attrs: {
                  label: "Tên",
                  required: true,
                },
              },
              description: {
                type: "textLarge",
                attrs: {
                  label: "Mô tả",
                },
              },
              baseUrl: {
                attrs: {
                  label: "Base URL",
                  required: true,
                },
              },
              shopeeName: {
                attrs: {
                  label: "Shopee Name",
                },
              },
              mktPlatformId: {
                type: "XAutocomplete",
                attrs: {
                  label: "Nền tảng MKT",
                  required: true,
                  "item-value": "_id",
                  "item-text": "name",
                  xOptions: {
                    content: {
                      async itemsLoader() {
                        const { items } = await coreApiClient.call(
                          "mktPlatforms",
                          "findAll",
                          {
                            limit: -1,
                          }
                        );
                        return items;
                      },
                    },
                  },
                },
                ext: {
                  onInput(data, self) {
                    const inputWrapperContainer =
                      self.inputContainers.productComboIds;
                    if (inputWrapperContainer && inputWrapperContainer.value) {
                      const inputContainer =
                        inputWrapperContainer.value.inputContainer;
                      if (inputContainer && inputContainer.value) {
                        inputContainer.value.suggest(true);
                      }
                    }
                  },
                },
              },
              mktUserId: {
                type: "XAutocomplete",
                attrs: {
                  label: "User MKT",
                  required: true,
                  "item-value": "_id",
                  "item-text": "name",
                  xOptions: {
                    content: {
                      async itemsSuggestor(search, value, limit) {
                        const { items } = await coreApiClient.call(
                          "users",
                          "findAll",
                          {
                            payload: JSON.stringify({
                              limit,
                              search,
                              filters: [
                                {
                                  key: "_id",
                                  operator: "equal_to",
                                  value,
                                },
                              ],
                            }),
                          }
                        );
                        return items;
                      },
                    },
                  },
                },
              },
              contentUserId: {
                type: "XAutocomplete",
                attrs: {
                  label: "User Content",
                  required: true,
                  "item-value": "_id",
                  "item-text": "name",
                  xOptions: {
                    content: {
                      async itemsSuggestor(search, value, limit) {
                        const { items } = await coreApiClient.call(
                          "users",
                          "findAll",
                          {
                            payload: JSON.stringify({
                              limit,
                              search,
                              filters: [
                                {
                                  key: "_id",
                                  operator: "equal_to",
                                  value,
                                },
                              ],
                            }),
                          }
                        );
                        return items;
                      },
                    },
                  },
                },
              },
              designUserId: {
                type: "XAutocomplete",
                attrs: {
                  label: "User Design",
                  required: true,
                  "item-value": "_id",
                  "item-text": "name",
                  xOptions: {
                    content: {
                      async itemsSuggestor(search, value, limit) {
                        const { items } = await coreApiClient.call(
                          "users",
                          "findAll",
                          {
                            payload: JSON.stringify({
                              limit,
                              search,
                              filters: [
                                {
                                  key: "_id",
                                  operator: "equal_to",
                                  value,
                                },
                              ],
                            }),
                          }
                        );
                        return items;
                      },
                    },
                  },
                },
              },
              productGroupId: {
                type: "XAutocomplete",
                attrs: {
                  label: "Nhóm sản phẩm",
                  "item-value": "_id",
                  "item-text": "sku",
                  clearable: true,
                  xOptions: {
                    content: {
                      async itemsSuggestor(search, value, limit) {
                        const { items } = await coreApiClient.call(
                          "productGroups",
                          "findAll",
                          {
                            payload: JSON.stringify({
                              limit,
                              search,
                              filters: [
                                {
                                  key: "countryId",
                                  operator: "equal_to",
                                  value: market.countryId,
                                },
                                {
                                  key: "_id",
                                  operator: "equal_to",
                                  value,
                                },
                              ],
                            }),
                          }
                        );
                        return items;
                      },
                    },
                  },
                },
                ext: {
                  emptyValue: "",
                },
              },
              giftedProductGroupId: {
                type: "XAutocomplete",
                attrs: {
                  label: "Nhóm sản phẩm quà tặng",
                  "item-value": "_id",
                  "item-text": "sku",
                  clearable: true,
                  xOptions: {
                    content: {
                      async itemsSuggestor(search, value, limit) {
                        const { items } = await coreApiClient.call(
                          "productGroups",
                          "findAll",
                          {
                            payload: JSON.stringify({
                              limit,
                              search,
                              filters: [
                                {
                                  key: "countryId",
                                  operator: "equal_to",
                                  value: market.countryId,
                                },
                                {
                                  key: "_id",
                                  operator: "equal_to",
                                  value,
                                },
                              ],
                            }),
                          }
                        );
                        return items;
                      },
                    },
                  },
                },
                ext: {
                  emptyValue: "",
                },
              },
              productComboIds: {
                type: "XAutocomplete",
                attrs: {
                  label: "Combo sản phẩm",
                  "item-value": "_id",
                  "item-text": "name",
                  multiple: true,
                  xOptions: {
                    content: {
                      async itemsSuggestor(search, value, limit, self) {
                        const mktPlatformId = self.context().context().inputs
                          .mktPlatformId.value;
                        const { items } = await coreApiClient.call(
                          "productCombos",
                          "findAll",
                          {
                            payload: JSON.stringify({
                              limit,
                              search,
                              filters: [
                                {
                                  key: "marketId",
                                  operator: "equal_to",
                                  value: market._id,
                                },
                                value && value.length
                                  ? null
                                  : {
                                      key: "status",
                                      operator: "not_equal_to",
                                      value: "nb",
                                    },
                                {
                                  key: "_id",
                                  operator: "in",
                                  value,
                                },
                                {
                                  key: "mktPlatformIds",
                                  operator: "equal_to",
                                  value: mktPlatformId,
                                },
                              ],
                            }),
                          }
                        );
                        return items;
                      },
                    },
                  },
                },
                ext: {
                  condition(self) {
                    return self.inputs.mktPlatformId.value;
                  },
                },
              },
            },
            insertForm: {
              content: {
                fieldNames: [
                  "name",
                  "description",
                  "baseUrl",
                  "mktPlatformId",
                  "mktUserId",
                  "contentUserId",
                  "designUserId",
                  "productGroupId",
                  "giftedProductGroupId",
                  "productComboIds",
                  "shopeeName",
                ],
              },
            },
            editForm: {
              content: {
                fieldNames: userManager.checkRole(
                  ["mkt", "tp", "rd", "rdl"],
                  true
                )
                  ? [
                      "name",
                      "description",
                      "baseUrl",
                      "mktPlatformId",
                      "productGroupId",
                      "giftedProductGroupId",
                      "productComboIds",
                      "shopeeName",
                    ]
                  : [
                      "name",
                      "description",
                      "baseUrl",
                      "mktPlatformId",
                      "mktUserId",
                      "contentUserId",
                      "designUserId",
                      "productGroupId",
                      "giftedProductGroupId",
                      "productComboIds",
                      "shopeeName",
                    ],
              },
            },
            delete: {
              confirmDisplayField: "name",
            },
          },
        },
      };
    },
  },
  async created() {
    this.market = await coreApiClient.call("markets", "findById", {
      id: this.$route.params.marketId,
    });
    this.tableOptions = this.makeTableOptions(this.market);
  },
});
