





















































































































































import Vue from "vue";
import coreApiClient from "@/services/apis/coreApiClient";

export default Vue.extend({
  props: ["item"],
  data() {
    return {
      comboProductGroups: [],
      comboProductGroupMaxCount: {}
    };
  },
  computed: {
    comboProductGroupPropertyCount() {
      const items = [];
      for (const productGroup of this.comboProductGroups) {
        for (
          let i = 0;
          i < this.comboProductGroupMaxCount[productGroup._id];
          i++
        ) {
          for (const property of productGroup.properties) {
            items.push({
              productGroup,
              property,
              i
            });
          }
        }
      }
      return items;
    }
  },
  async created() {
    this.comboProductGroups = [];
    const addedProductGroupIds = {};
    for (const combo of this.item.productCombos) {
      for (const productItem of combo.productItems) {
        if (!productItem.productId && productItem.productGroupId) {
          if (!addedProductGroupIds[productItem.productGroupId]) {
            addedProductGroupIds[productItem.productGroupId] = true;
            const productGroup = await coreApiClient.call(
              "productGroups",
              "findById",
              {
                id: productItem.productGroupId
              }
            );
            this.comboProductGroups.push(productGroup);
          }
          if (
            !this.comboProductGroupMaxCount[productItem.productGroupId] ||
            this.comboProductGroupMaxCount[productItem.productGroupId] <
              productItem.count
          ) {
            this.comboProductGroupMaxCount[productItem.productGroupId] =
              productItem.count;
          }
        }
      }
    }
  }
});
